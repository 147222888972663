import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Article from "../components/article"

const Blog = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicBlogPost {
        edges {
          node {
            id
            first_publication_date
            last_publication_date
            data {
              article_post_date(formatString: "Do MMMM, YYYY")
              category
              paragraph {
                html
                text
              }
              article_title {
                html
                raw
                text
              }
              heading_image {
                alt
                url
              }
              author
              author_image {
                url
                alt
              }
            }
            tags
            type
            url
            prismicId
            uid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Container>
        <SEO
          title="Blog"
          keywords={[`blog`, `gatsby`, `prismic`]}
          description="Safetylogz blog for product announcements, news, and thoughts & experiences working within Health and Safety."
        />
        <BlogHeader>
          <h2>The Safetylogz Blog</h2>
          <p>
            Product announcements, news, and thoughts & experiences working
            within Health and Safety.
          </p>
        </BlogHeader>
        <ArticleWrapper>
          {data.allPrismicBlogPost.edges.map(blogPost => (
            <Article
              key={blogPost.node.uid}
              slug={blogPost.node.uid}
              data={blogPost.node.data}
            />
          ))}
        </ArticleWrapper>
      </Container>
    </Layout>
  )
}

export default Blog

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.45rem;
  @media screen and (max-width: 414px) {
    padding-bottom: 110px;
  }
`

const BlogHeader = styled.div`
  h2 {
    font-size: 28px;
  }
  p {
    font-size: 18px;
    max-width: 550px;
  }
`

const ArticleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;
  @media only screen and (max-width: 414px) {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
`
