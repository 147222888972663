import React from "react"
import styled from "styled-components"

import readingTime from "reading-time"
import { Link } from "gatsby"

const Article = props => {
  const readTime = readingTime(props.data.paragraph.text)
  return (
    <ArticleCard>
      <Link to={`/blog/post/${props.slug}`} className="c-article">
        <img
          alt={props.data.heading_image.alt}
          src={props.data.heading_image.url}
        />
        <div className="content__wrapper">
          <p className="post__date">{props.data.article_post_date}</p>
          <h3>{props.data.article_title.text}</h3>
          <span className="read__time">{readTime.text}</span>
          <div className="article__author">
            <img
              src={props.data.author_image.url}
              alt={
                props.data.author_image.alt ? props.data.author_image.alt : ""
              }
            />
            <p>
              By <span>{props.data.author}</span>
            </p>
          </div>
        </div>
      </Link>
    </ArticleCard>
  )
}

export default Article

const ArticleCard = styled.div`
  a {
    text-decoration: none;
    color: #2f4f5d;
  }
  a:visited,
  a:hover {
    text-decoration: none;
  }
  max-width: 400px;
  border: 1px solid #2f4f5d;
  border-radius: 5px;
  .content__wrapper {
    padding: 1rem;
  }
  .post__date {
    font-size: 14px;
    margin-bottom: 5px;
  }
  h3 {
    margin-bottom: 15px;
  }

  .read__time {
    font-size: 14px;
    margin: 10px 0;
    display: block;
  }

  .article__author {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin: 15px 0;
    padding: 0;
    img {
      width: 35px;
      border-radius: 50%;
      border: 1px solid black;
      margin: 0;
    }
    p {
      margin: 0 0 0 10px;
    }
    span {
      font-weight: bold;
    }
  }
`
